<div class="cmx-auto" style="background-color: #fff; margin-top: 80px; align-content: center;">
  <h3 class="page-title">{{'yubillySubscription' | translate}}</h3>
  <h2 class="page-title" *ngIf="fetching">
    <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
  </h2>

  <ng-container *ngIf="activeContainer==CONTAINER_MAIN">
    {{'activeSubscription'|translate}}: <b>{{subscriptionForUser.marketingName}}</b>
    <br />{{'startDate'|translate}}: <b>{{subscriptionForUser.startDate | date:'yyyy-MMM-dd'}}</b>
    <br />{{'endDate'|translate}}: <b>{{subscriptionForUser.endDate | date:'yyyy-MMM-dd'}}</b>
    <!--<br />{{'availableCredit'|translate}}: <b>{{subscriptionForUser.availableCredit}}</b>-->
    <br />
    <div class="table-sm" style="margin-top: 0%;">
      <table class="table table-striped table-sm" style="margin-top: 0%;">
        <thead>
          <tr>
            <td style="vertical-align: middle;"><b>{{'features'|translate}}</b></td>
            <td style="text-align: center;">{{subscriptionForUser.subFree.marketingName}}
              <br />{{subscriptionForUser.subFree.priceMonthly}}
              <span style="font-size: smaller;">{{subscriptionForUser.subFree.currency}}</span></td>
            <td style="text-align: center;">{{subscriptionForUser.subPro.marketingName}}
              <br />{{subscriptionForUser.subPro.priceMonthly}}
              <!--<br />{{subscriptionForUser.subPro.priceYearly}}-->
              <span style="font-size: smaller;">{{subscriptionForUser.subPro.currency}}</span></td>
            <td style="text-align: center;">{{subscriptionForUser.subGrowth.marketingName}}
                <br />{{subscriptionForUser.subGrowth.priceMonthly}}
                <!--<br />{{subscriptionForUser.subGrowth.priceYearly}}-->
                <span style="font-size: smaller;">{{subscriptionForUser.subGrowth.currency}}</span></td>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let feature of subscriptionForUser.featureTable">
            <td style="padding:0px;">{{feature.description}}</td>
            <td style="text-align: center; padding:0px;">
              {{feature.textFREE}}
              <span *ngIf="feature.textFREE.length===0">
                <span *ngIf="feature.forFREE"><fa-icon [icon]="iconOk"
                    style="color:green; font-size:20px;"></fa-icon></span>
                <span *ngIf="!feature.forFREE"><fa-icon [icon]="iconX"
                    style="color:red; font-size:20px;"></fa-icon></span>
                </span>
              </td>
            <td style="text-align: center; padding:0px;">
              {{feature.textPRO}}
              <span *ngIf="feature.textPRO.length===0">
              <span *ngIf="feature.forPRO"><fa-icon [icon]="iconOk"
                  style="color:green; font-size:20px;"></fa-icon></span>
              <span *ngIf="!feature.forPRO"><fa-icon [icon]="iconX"
                style="color:red; font-size:20px;"></fa-icon></span>
              </span>
            </td>
            <td style="text-align: center; padding:0px;">
              {{feature.textGROWTH}}
              <span *ngIf="feature.textGROWTH.length===0">
              <span *ngIf="feature.forGROWTH"><fa-icon [icon]="iconOk"
                  style="color:green; font-size:20px;"></fa-icon></span>
              <span *ngIf="!feature.forGROWTH"><fa-icon [icon]="iconX"
                style="color:red; font-size:20px;"></fa-icon></span>
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td style="text-align: center;">
              <!--<span *ngIf="subscriptionForUser.subFree.hasUpgrade">
                <button id="upgrade" (click)="upgrade()">{{'upgrade'|translate}}</button>
              </span>-->
            </td>
            <td style="text-align: center;">
              <span *ngIf="subscriptionForUser.subPro.hasPayment && !subscriptionForUser.autoRenewal">
                <button id="renewPRO" (click)="renew('PRO')">{{'select'|translate}}</button>
              </span>
            </td>
            <td style="text-align: center;">
              <span *ngIf="subscriptionForUser.subGrowth.hasPayment && !subscriptionForUser.autoRenewal">
                <button id="renewGROWTH" (click)="renew('GROWTH')">{{'select'|translate}}</button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <span *ngIf="subscriptionForUser.hasSub && subscriptionForUser.autoRenewal">
        <input type="checkbox" id="recurringStatus" checked disabled
        style="width: 1.2rem; height: 1.2rem;  vertical-align: center; margin-left:0rem; margin-top: 0.1rem; margin-right: 0.5rem;" />
        <label for="recurring" style="height: 1.2rem;  margin-top: 0.0rem; vertical-align: top;"> {{'autoRenewal'|translate}}</label>
        <br />
        <button id="stop" (click)="stopAutoRenewal(confirmationTemplate)">{{'stopAutoRenewal'|translate}}</button>
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="activeContainer==CONTAINER_CONFIRM">
    <span *ngIf="selectedType=='PRO'">
      {{'subscription'|translate}}: <b>{{subscriptionForUser.subPro.marketingName}}</b>
      <form name="period-form">
        <div>
          {{'paymentPeriod'|translate}}
          <br />
          <input type="radio" id="monthly" name="periods" value="1" (click)="onPeriodChange(1)" checked />
          <label for="monthly">{{'monthly'|translate}}: {{subscriptionForUser.subPro.priceMonthly}} {{subscriptionForUser.subPro.currency}}</label>
          <br />
          <input type="radio" id="yearly" name="periods" value="2" (click)="onPeriodChange(2)" />
          <label for="yearly">{{'yearly'|translate}}: {{subscriptionForUser.subPro.priceYearly}} {{subscriptionForUser.subPro.currency}}</label>
        </div>
      </form>
      <div *ngIf="selectedPeriod === PERIOD_MONTHLY">
        <!--{{'creditToUse'|translate}}: {{subscriptionForUser.subPro.creditToUseInMonthly}} {{subscriptionForUser.subPro.currency}}
        <br />-->
        <!--{{'amountToPay'|translate}}:<b> {{subscriptionForUser.subPro.amountToPayMonthly}}</b> {{subscriptionForUser.subPro.currency}}-->
      </div>
      <div *ngIf="selectedPeriod === PERIOD_YEARLY">
        <!--{{'creditToUse'|translate}}: {{subscriptionForUser.subPro.creditToUseInYearly}} {{subscriptionForUser.subPro.currency}}
        <br />-->
        <!--{{'amountToPay'|translate}}:<b> {{subscriptionForUser.subPro.amountToPayYearly}}</b> {{subscriptionForUser.subPro.currency}}-->
      </div>
      <input type="checkbox" id="recurring" (click)="autoRenewalChecked()" checked
      style="width: 1.2rem; height: 1.2rem;  vertical-align: center; margin-left:0rem; margin-top: 0.5rem; margin-right: 0.5rem;" />
      <label for="recurring" style="height: 1.2rem;  margin-top: 0.4rem; vertical-align: top;"> {{'autoRenewal'|translate}}</label>
      <br />
      <p style="text-align: center; margin-top: 6px;">
        <button class="btn btn-info btn-sm" (click)="onCheckout(subscriptionForUser.subPro)">{{'checkout'|translate}}</button>
        <br />
        <button class="btn btn-dark btn-sm" (click)="cancelCheckout()">{{'cancel'|translate}}</button>
      </p>
    </span>
    <span *ngIf="selectedType=='GROWTH'">
      {{'subscription'|translate}}: <b>{{subscriptionForUser.subGrowth.marketingName}}</b>
      <form name="period-form">
        <div>
          {{'paymentPeriod'|translate}}
          <br />
          <input type="radio" id="monthly" name="periods" value="1" (click)="onPeriodChange(1)" checked />
          <label for="monthly">{{'monthly'|translate}}: {{subscriptionForUser.subGrowth.priceMonthly}} {{subscriptionForUser.subPro.currency}}</label>
          <br />
          <input type="radio" id="yearly" name="periods" value="2" (click)="onPeriodChange(2)" />
          <label for="yearly">{{'yearly'|translate}}: {{subscriptionForUser.subGrowth.priceYearly}} {{subscriptionForUser.subPro.currency}}</label>
        </div>
      </form>
      <div *ngIf="selectedPeriod === PERIOD_MONTHLY">
        <!--{{'creditToUse'|translate}}: {{subscriptionForUser.subPro.creditToUseInMonthly}} {{subscriptionForUser.subPro.currency}}
        <br />-->
        <!--{{'amountToPay'|translate}}:<b> {{subscriptionForUser.subPro.amountToPayMonthly}}</b> {{subscriptionForUser.subPro.currency}}-->
      </div>
      <div *ngIf="selectedPeriod === PERIOD_YEARLY">
        <!--{{'creditToUse'|translate}}: {{subscriptionForUser.subPro.creditToUseInYearly}} {{subscriptionForUser.subPro.currency}}
        <br />-->
        <!--{{'amountToPay'|translate}}:<b> {{subscriptionForUser.subPro.amountToPayYearly}}</b> {{subscriptionForUser.subPro.currency}}-->
      </div>
      <input type="checkbox" id="recurring" (click)="autoRenewalChecked()" checked
      style="width: 1.2rem; height: 1.2rem;  vertical-align: center; margin-left:0rem; margin-top: 0.5rem; margin-right: 0.5rem;" />
      <label for="recurring" style="height: 1.2rem;  margin-top: 0.4rem; vertical-align: top;"> {{'autoRenewal'|translate}}</label>
      <br />
      <p style="text-align: center; margin-top: 6px;">
        <button class="btn btn-info btn-sm" (click)="onCheckout(subscriptionForUser.subGrowth)">{{'checkout'|translate}}</button>
        <br />
        <button class="btn btn-dark btn-sm" (click)="cancelCheckout()">{{'cancel'|translate}}</button>
      </p>
    </span>

  </ng-container>

  <ng-container *ngIf="activeContainer==CONTAINER_PAYMENT">
    <p><i>{{'receiptEmail'|translate}}</i>: <span id="receiptEmailValue"></span>
      <br /><i>{{'description'|translate}}</i>: <span id="descriptionValue"></span>
      <br /><i>{{'amount'|translate}}</i>: <span id="amountValue"></span>
    </p>

    <!-- Display a payment form -->
    <form id="payment-form" style="border-style: solid;">
      <!-- <div id="link-authentication-element">-->
      <!--Stripe.js injects the Link Authentication Element-->
      <!-- </div>-->
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <button class="btn-info" id="submit" (click)="handleSubmit()">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">{{'payNow'|translate}}</span>
      </button>
      <br />
      <button class="btn-dark"  id="cancel-payment" (click)="cancelPayment()">
        <span>{{'cancel'|translate}}</span>
      </button>

      <div id="payment-message" class="hidden"></div>
    </form>

    <p></p>
  </ng-container>

  <ng-container *ngIf="activeContainer==CONTAINER_PAYMENT_RESULT">
    <!--Payment done successfuly.
    Your account will be updated shortly.
    [OK] 'returns Home'-->
    <br /><br />
    <p>{{'paymentSucceededMessage'|translate}}</p>
    <br /><br />
    <button id="ok-payment-result" (click)="paymentDone()">
      <span>{{'ok'|translate}}</span>
    </button>
  </ng-container>

  <ng-template #confirmationTemplate let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'pleaseConfirm'|translate}}</h4>
      <!--<button type="button-close" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>-->
    </div>
    <div class="modal-body">
      {{'areYouSureToStopAutoRenewal'|translate}}
    </div>

    <div class="container-sm">
      <div class="row">
        <div class="col">
          <button type="submit" (click)="c('ok')" class="btn btn-block btn-danger btn-sm">{{'ok'|translate}}</button>
        </div>
        <div class="col">
          <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
            {{'cancel'|translate}}</button>
        </div>
      </div>
    </div>
    <p></p>

  </ng-template>

  <!-- Old stuff to delete -->
  <ng-container *ngIf="activeContainer==CONTAINER_NOT_READY">

  </ng-container>

  <!--<ng-container *ngIf="activeContainer==CONTAINER_NOT_READY">
    {{'currentSubscription'|translate}}: <b>{{subscription.marketingName}}</b>
    {{'startDate'|translate}}: <b>{{subscription.startDate}}</b>
    {{'endDate'|translate}}: <b>{{subscription.endDate}}</b>
    {{'availableCredit'|translate}}: <b>{{subscription.availableCredit}}</b>
    <ng-container *ngIf="subscription.hasSubscription">
      <b>{{subscription.subscriptionBrand}}</b>
      <ng-container *ngIf="subscription.subscriptionType==='MERCHANT_PROMOTION'">
        <br />{{'providedBy'|translate}}: <b>{{subscription.providedBy}}</b>
      </ng-container>
      <br />
      {{'features'|translate}}
      <ul>
        <li *ngFor="let feature of subscription.features">
          {{feature.description}}
          <span *ngIf="feature.enabled"><fa-icon [icon]="iconOk" style="color:green; font-size:20px;"></fa-icon></span>
          <span *ngIf="!feature.enabled"><fa-icon [icon]="iconX" style="color:red; font-size:20px;"></fa-icon></span>
        </li>
      </ul>
      {{'startDate'|translate}}: {{subscription.startDate | date:'yyyy-MMM-dd'}}
      <br />
      {{'endDate'|translate}}: <b>{{subscription.endDate | date:'yyyy-MMM-dd'}}</b>

      <ng-container *ngIf="subscription.hasPayment">
        <p>{{'paymentDueDate'|translate}}:
          <br /> {{subscription.paymentDueDate | date:'yyyy-MMM-dd'}}
        </p>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!subscription.hasSubscription">
      <b>{{subscription.subscriptionBrand}}</b>
      <br />
      {{'features'|translate}}
      <ul>
        <li *ngFor="let feature of subscription.features">
          {{feature}}
        </li>
      </ul>

    </ng-container>

    <ng-container *ngIf="showCheckout">
      <form name="period-form">
        <div>
          {{'paymentPeriod'|translate}}
          <br />
          <input type="radio" id="monthly" name="periods" value="1" (click)="onPeriodChange(1)" checked />
          <label for="monthly">{{'monthly'|translate}}: {{subscription.pricePerMonth}} {{subscription.currency}}</label>
          <br />
          <input type="radio" id="yearly" name="periods" value="2" (click)="onPeriodChange(2)" />
          <label for="yearly">{{'yearly'|translate}}: {{subscription.pricePerYear}} {{subscription.currency}}</label>
        </div>
      </form>
      {{'availableCredit'|translate}}: {{subscription.availableCredit}} {{subscription.currency}}
      <br />
      <div *ngIf="selectedPeriod === PERIOD_MONTHLY">
        {{'amountToPay'|translate}}:<b> {{subscription.amountToPayMonthly}}</b> {{subscription.currency}}
      </div>
      <div *ngIf="selectedPeriod === PERIOD_YEARLY">
        {{'amountToPay'|translate}}:<b> {{subscription.amountToPayYearly}}</b> {{subscription.currency}}
      </div>
      <br />
      <p style="text-align: center">
        <button class="btn btn-info btn-sm" (click)="onCheckout()">{{'checkout'|translate}}</button>
      </p>
    </ng-container>
  </ng-container>
-->
  <ng-container *ngIf="activeContainer==CONTAINER_NO_SUB">
    <div class="table-sm" style="margin-top: 0%;">
      <table class="table table-striped table-sm" style="margin-top: 0%;">
        <thead>
          <tr>
            <td></td>
            <td style="text-align: center;">Free<br />$0</td>
            <td style="text-align: center;">PRO<br />$5</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let feature of subscriptionForUser.features">
            <td>{{feature.description}}</td>
            <td></td>
            <td>
              <span *ngIf="feature.enabled"><fa-icon [icon]="iconOk"
                  style="color:green; font-size:20px;"></fa-icon></span>
              <span *ngIf="!feature.enabled"><fa-icon [icon]="iconX"
                  style="color:red; font-size:20px;"></fa-icon></span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td style="text-align: center;">Upgrade</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>
