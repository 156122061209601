<div class="cmx-auto" style="background-color: #fff; margin-top: 80px; align-content: center;">
  <ng-container>
    <h2 class="page-title">{{'membershipPayment' | translate}}</h2>
    <h2 class="page-title" *ngIf="fetching">
      <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
    </h2>

    <p><i>{{'receiptEmail'|translate}}</i>: <span id="receiptEmailValue"></span>
      <br /><i>{{'description'|translate}}</i>: <span id="descriptionValue"></span>
      <br /><i>{{'amount'|translate}}</i>: <span id="amountValue"></span>
    </p>

    <!-- Display a payment form -->
    <form id="payment-form" style="border-style: solid;">
      <!-- <div id="link-authentication-element">-->
      <!--Stripe.js injects the Link Authentication Element-->
      <!-- </div>-->
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <button id="submit" (click)="handleSubmit()">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">{{'payNow'|translate}}</span>
      </button>
      <div id="payment-message" class="hidden"></div>
    </form>
  </ng-container>
</div>
