// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://yubilly.ddns.net/yservice',
  // apiUrl: 'http://localhost:8080/yservice',
   apiUrl: 'https://api1.yubilly.com',
  // apiUrl: 'https://yservice2-test-brb5gjdjcxdwg3ev.canadacentral-01.azurewebsites.net',
  //apiUrl: 'https://api1.yubilly.com', //azure
  recaptcha: {
    siteKeyV3: '6LdC72QeAAAAACuLwMBvFvXNl5lm-0ZaQ4lD27dy'
  },
  recaptchaNotRobot: {
    siteKeyNotRobot: '6LfpZe0eAAAAAH5QkdKyctD2HyyWWmelJYg862Uw'
    // siteKeyNotRobot: '6LdnFdEaAAAAAC32N5zW-_el_WwpLty131s05op3'
    // siteKeyNotRobot: '6Le_SVkeAAAAAJtpnudXWKN4uF21M1ZjczzXqcRK'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
