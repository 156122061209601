import { NameUpdateRequest, EmailUpdateRequest, EmailUpdate, PasswordChange } from './../../shared/yubilly-data';
import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth/';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { YMailOption, NewUser } from '@app/shared/yubilly-data';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private baseUrl = environment.apiUrl.concat('/users');
  private signedIn = false;
  private emailVerified: boolean;
  private userName: string;
  private firstName: string;
  private lastName: string;
  private email: string;
  /*user has a group in Cognito : YMAIL_USER, MERCHANT_USER, ADMIN
  */
  private userGroup: string;

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    private router: Router) {
  }

  setAppUser(
    signedIn: boolean,
    emailVerified: boolean,
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    userGroup: string): void {
    this.signedIn = signedIn;
    this.emailVerified = emailVerified;
    this.userName = userName; // ymail is the username.//
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.userGroup = userGroup;
  }

  getUserName(): string {
    return this.userName;
  }
  getFirstName(): string {
    return this.firstName;
  }
  getLastName(): string {
    return this.lastName;
  }
  getEmail(): string {
    return this.email;
  }
  isUserSignedIn(): boolean {
    return this.signedIn;
  }
  isEmailVerified(): boolean {
    return this.emailVerified;
  }
  isUserMerchantUser(): boolean {
    if (typeof this.userGroup === 'undefined') {
      return false;
    } else if (this.userGroup === null) {
      return false;
    } else if (this.userGroup === '') {
      return false;
    } else {
      return this.userGroup.toString().includes('MERCHANT_USER');
    }
  }
  isUserBasicMember(): boolean {
    if (typeof this.userGroup === 'undefined') {
      return false;
    } else if (this.userGroup === null) {
      return false;
    } else if (this.userGroup === '') {
      return false;
    } else {
      return this.userGroup.toString().includes('MEMBER_BASIC');
    }
  }
  isUserPremiumMember(): boolean {
    if (typeof this.userGroup === 'undefined') {
      return false;
    } else if (this.userGroup === null) {
      return false;
    } else if (this.userGroup === '') {
      return false;
    } else {
      return this.userGroup.toString().includes('MEMBER_PREMIUM');
    }
  }
  public verifyUserStatus(): void {
    Auth.currentAuthenticatedUser().then((user) => {
      this.signedIn = true;
      this.setAppUser(
        true,
        user.attributes.email_verified,
        user.username,
        user.attributes.name,
        user.attributes.family_name,
        user.attributes.email,
        user.signInUserSession.accessToken.payload['cognito:groups']);
    }).catch(() => {
      this.signedIn = false;
      this.setAppUser(false, null, null, null, null, null, null);
    });
  }

  /*profileUpdateTest(): void {
    // const promise = new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then((user) => {
      Auth.updateUserAttributes(
        user, { profile: 'mithat.karaoglu@imedianorthside.com' }
      ).then((result) => {
        console.log('result:' + result);
        //resolve(result);
      }).catch((err) => {
        console.log('result:' + err.message);
        //reject(err);
      });
    }).catch((err) => {
      console.log('result:' + err.message);
      // reject(err);
    });
    // });
  }*/

  updateUserFirstAndLastName(newName: string, newLastName: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser().then((user) => {
        Auth.updateUserAttributes(
          user, { name: newName, family_name: newLastName }
        ).then((result) => {
          this.firstName = newName;
          this.lastName = newLastName;
          resolve(result);
        }).catch((err) => {
          reject(err);
        });
      }).catch((err) => {
        reject(err);
      });
    });
    return promise;
  }

  updateUserFirstAndLastNameV2(newName: string, newLastName: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/name/${newName}/${newLastName}`, {
    });
  }

  refreshNameAndLastNameAfterUpdate(): void {
    Auth.currentAuthenticatedUser({
      bypassCache: true
      // Optional, By default is false.
      // If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      this.firstName = user.attributes.name;
      this.lastName = user.attributes.family_name;
    }).catch(err => console.log(err));
  }

  submitEmailUpdateRequest(newEmail: string, captchaString: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/emailUpdateRequest/${newEmail}`, captchaString, {
      // params: new HttpParams().set('language', this.translate.currentLang)
    });
  }

  updateEmail(newEmail: string, verificationCode: number): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/email/${newEmail}/${verificationCode}`, {
    });
  }
  /* *********** */
  submitEmailUpdateRequestV3(emailUpdateRequest: EmailUpdateRequest): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/emailUpdateRequest`, emailUpdateRequest, {
      // params: new HttpParams().set('language', this.translate.currentLang)
    });
  }

  updateEmailV3(emailUpdate: EmailUpdate): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/email`, emailUpdate, {
    });
  }

  updateUserFirstAndLastNameV3(nameUpdateRequest: NameUpdateRequest): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/name` ,nameUpdateRequest , {
    });
  }

  changePasswordV3(passwordChange: PasswordChange): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/passwordChange` ,passwordChange , {
    });
  }
  /* ********* */

  forgotUserName(verifiedEmail: string, captchaString: string): Observable<any> {
    return this.httpClient.post<boolean>(`${this.baseUrl}/nameRecovery/${verifiedEmail}`, captchaString, {
      // params: new HttpParams().set('language', this.translate.currentLang),
    });
  }

  forgotPasswordRequireReset(userName: string): Promise<any> {
    return Auth.forgotPassword(userName);
  }

  forgotPasswordSubmitWithVerificationCode(userName: string, verificationCode: string, newPassword: string): Promise<any> {
    return Auth.forgotPasswordSubmit(userName, verificationCode, newPassword);
  }

  verifyPasswordBySignIn(password: string): Promise<any> {
    return Auth.signIn(this.getUserName(), password);
  }

  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          Auth.changePassword(
            user, oldPassword, newPassword
          ).then((result) => {
            resolve(result);
          }).catch((err) => {
            reject(err);
          });
        }).catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  requestVerificationCodeForSignUp(email: string, captchaString: string): Observable<any> {
    return this.httpClient.post<boolean>(`${this.baseUrl}/signUp/${email}`, captchaString, {
      params: new HttpParams().set('language', this.translate.currentLang)
    });
  }

  submitVerificationCodeForSignUp(
    email: string, verificationCode: number,
    firstName: string, lastName: string, captchaString: string): Observable<YMailOption[]> {
    return this.httpClient.post<YMailOption[]>
      (`${this.baseUrl}/signUp/${email}/${verificationCode}`, captchaString, {
        params: new HttpParams()
          .set('language', this.translate.currentLang)
          .set('firstName', firstName)
          .set('lastName', lastName),
      });
  }

  submitNewUser(newUser: NewUser): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/signUp`, newUser, {
      params: new HttpParams()
        .set('language', this.translate.currentLang),
    });
  }

  signOut(): void {
    // console.log("signOut starting");
    Auth.signOut()
      .then(() => {
        this.setAppUser(false, null, null, null, null, null, null);
        this.router.navigateByUrl('/sign-in');
        //return true;
      })
      .catch(err => {
        console.log(err);
        this.setAppUser(false, null, null, null, null, null, null);
        this.router.navigateByUrl('/sign-in');
        //return false;
      });
  }

  public getAccessToken = (): Promise<string> => {
    return Auth.currentAuthenticatedUser()
      .then(user => {
        return !!user && !user.expired ? user.signInUserSession.accessToken.jwtToken : null;
      });
  }
}
