import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentIntentRequest, PaymentIntent } from '@app/shared/yubilly-data';
import { YubillyService } from '@app/shared/yubilly.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// These two are coming from stripe.js included in index.html.
// Other ways did not work. This way will also wok on clients side implements...
declare var stripe: any;
declare var elements: any;
//
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  iconSpinner = faSpinner;
  // emailAddress: string;
  fetching: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  paymentIntent: PaymentIntent;
  paymentElement: any;

  //
  constructor(private yubillyService: YubillyService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    console.log("AfterViewInit...");
    /*document
      .querySelector("#payment-form")
      .addEventListener("submit", this.handleSubmit);*/
    this.createPaymentIntent();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.paymentElement.unmount();
  }

  createPaymentIntent() {
    this.fetching = true;
    // Fix this. period and credit information will come from the form user chooses
    /*const item: PaymentIntentRequest = {
      subId: 20, period: 1, availablePoint: 0, availableCredit: "0.00", amountToPay:"",
      currency: '', autoRenewal: false
    };*/
    const item = {} as PaymentIntentRequest;
    this.yubillyService
      .createPaymentIntent(item)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.paymentIntent = res;
          this.fetching = false;
          console.log(this.paymentIntent);
          //
          (document.getElementById('receiptEmailValue') as HTMLSpanElement).textContent = this.paymentIntent.receiptEmail;
          (document.getElementById('descriptionValue') as HTMLSpanElement).textContent = this.paymentIntent.description;
          (document.getElementById('amountValue') as HTMLSpanElement).textContent =
          (this.paymentIntent.amount +' '+ this.paymentIntent.currency.toUpperCase());
          //
          const clientSecret = this.paymentIntent.clientSecret;
          const appearance = {
            theme: 'stripe',
            variables: {
              // colorPrimary: '#0570de',
              // colorBackground: '#ffffff',
              // colorText: '#30313d',
              // colorDanger: '#df1b41',
              fontFamily: 'Ideal Sans, system-ui, sans-serif',
              spacingUnit: '2px',
              borderRadius: '2px',
            }
          };
          elements = stripe.elements({ appearance, clientSecret });
          // const linkAuthenticationElement = elements.create("linkAuthentication");
          // linkAuthenticationElement.mount("#link-authentication-element");
          /*linkAuthenticationElement.on('change', (event) => {
            this.emailAddress = event.value.email;
          });*/
          //
          // !!! add the amount to button (this.paymentIntent.amount + this.paymentIntent.currency);
          //
          const paymentElementOptions = {
            layout: "tabs",
            fields: {
              billingDetails: {
                // name: 'never',
                email: 'never',
              }
            }
          };

          this.paymentElement = elements.create("payment", paymentElementOptions);
          this.paymentElement.mount("#payment-element");
        },
        (error) => {
          console.log(error);
          this.fetching = false;
        }
      );
  }


  async handleSubmit() {
    // e.preventDefault();
    (document.getElementById('submit') as HTMLButtonElement).disabled;
    document.querySelector("#spinner").classList.remove("hidden");
    document.querySelector("#button-text").classList.add("hidden");
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Fix this later
        // goto payment completion page to show succeed and then return home
        return_url: "http://localhost:4200",
        // return_url: "http://localhost:8080/ypaytest/payment-done.html",
        payment_method_data: {
          billing_details: {
            // name: 'Jenny Rosen',
            email: this.paymentIntent.receiptEmail,
          }
        },
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`with the following parameters
    // ?payment_intent=pi_3MorZ6JGQGKuwjSM1COhsHYB
    // &payment_intent_client_secret=pi_3MorZ6JGQGKuwjSM1COhsHYB_secret_sl7ADNWOM8zcntMmrqg1s4Vxt
    // &redirect_status=succeeded
    if (error.type === "card_error" || error.type === "validation_error") {
      console.log(error.message);
    } else {
      console.log("An unexpected error occurred.");
    }
    !(document.getElementById('submit') as HTMLButtonElement).disabled;
    document.querySelector("#spinner").classList.add("hidden");
    document.querySelector("#button-text").classList.remove("hidden");
  }

  // Fetches the payment intent status after payment submission
  checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = stripe.retrievePaymentIntent(clientSecret);
    switch (paymentIntent.status) {
      case "succeeded":
        console.log("Payment succeeded!");
        break;
      case "processing":
        console.log("Your payment is processing.");
        break;
      case "requires_payment_method":
        console.log("Your payment was not successful, please try again.");
        break;
      default:
        console.log("Something went wrong.");
        break;
    }
  }

}
