// import { PaymentComponent } from './components/payment/payment.component';
// import { Membership } from './shared/client-data';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ReceiptListComponent } from './components/receipts/receipt-list.component';
import { MerchantsComponent } from './components/merchants/merchants.component';
import { LabelsComponent } from './components/labels/labels.component';
import { CurrenciesComponent } from './components/currencies/currencies.component';
import { RecycleComponent } from './components/recycle/recycle.component';
import { OffersComponent } from './components/offers/offers.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { MembershipComponent } from './components/membership/membership.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { SignOutComponent } from './components/auth/sign-out/sign-out.component';
import { AuthGuard } from './components/auth/auth.guard';
import { UnauthGuard } from './components/auth/unauth.guard';
import { SupportComponent } from './components/support/support.component';
import { ClientsComponent } from './components/clients/clients.component';
import { UploadComponent } from './components/upload/upload.component';
import { OthersComponent } from './components/others/others.component';
import { ClientOffersComponent } from './components/client-offers/client-offers.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '.' },
  //{ path: '/', pathMatch: 'full', redirectTo: '.' },
  { path: '.', component: HomeComponent, canActivate: [UnauthGuard] },
  { path: 'receipts', component: ReceiptListComponent, canActivate: [AuthGuard] },
  { path: 'merchants', component: MerchantsComponent, canActivate: [AuthGuard] },
  { path: 'labels', component: LabelsComponent, canActivate: [AuthGuard] },
  { path: 'currencies', component: CurrenciesComponent, canActivate: [AuthGuard] },
  { path: 'recycles', component: RecycleComponent, canActivate: [AuthGuard]},
  { path: 'others', component: OthersComponent, canActivate: [AuthGuard] },
  { path: 'offers', component: OffersComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'support', component: SupportComponent, canActivate: [AuthGuard] },
  { path: 'subscription', component: MembershipComponent, canActivate: [AuthGuard] },
  // { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: 'clients', component: ClientsComponent, canActivate: [AuthGuard] },
  { path: 'upload', component: UploadComponent, canActivate: [AuthGuard] },
  { path: 'sign-in', component: SignInComponent, canActivate: [UnauthGuard] },
  { path: 'sign-up', component: SignUpComponent, canActivate: [UnauthGuard] },
  { path: 'sign-out', component: SignOutComponent, canActivate: [UnauthGuard] },
  { path: 'manage-offers', component: ClientOffersComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
