import { Intro, OfferLink, Other, SubscriptionForUser, PaymentIntent, PaymentIntentRequest } from './yubilly-data';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '@app/components/auth/auth.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Receipt, ReceiptItems, Merchant, Label, Currency, ReceiptWithFilters, MerchantWithCurrency, ReceiptWithTotals, Offer } from '@app/shared/yubilly-data';

@Injectable({
  providedIn: 'root'
})
export class YubillyService {
  private baseUrl = environment.apiUrl;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient) {
  }

  getReceipts(merchantName: string, labelId: number, periodId: number, currency: string): Observable<Receipt[]> {
    return this.httpClient.get<Receipt[]>(`${this.baseUrl}/receipts`, {
      params: new HttpParams()
        .set('merchantName', merchantName)
        .set('labelId', labelId.toString())
        .set('periodId', periodId.toString())
        .set('currency', currency.toString()),
      /*params: new HttpParams({fromString: "page=1&pageSize=20"}),*/
      /*or better way: .set("page","1").set("pageSize","20"); */
    });
  }

  getReceiptItems(receiptId: number): Observable<ReceiptItems[]> {

    return this.httpClient.get<any>(
      `${this.baseUrl}/receipts/${receiptId}/items`, {
    });
  }

  getReceiptsWithTotals(merchantName: string, labelId: number, periodId: number, currency: string): Observable<ReceiptWithTotals[]> {
    return this.httpClient.get<ReceiptWithTotals[]>(`${this.baseUrl}/receipts/withTotals`, {
      params: new HttpParams()
        .set('merchantName', merchantName)
        .set('labelId', labelId.toString())
        .set('periodId', periodId.toString())
        .set('currency', currency.toString()),
      /*params: new HttpParams({fromString: "page=1&pageSize=20"}),*/
      /*or better way: .set("page","1").set("pageSize","20"); */
    });
  }

  getReceiptsWithFilters(): Observable<ReceiptWithFilters[]> {
    return this.httpClient.get<ReceiptWithFilters[]>(`${this.baseUrl}/receipts/withFilters`, {
      /*params: new HttpParams({fromString: "page=1&pageSize=20"}),*/
      /*or better way: .set("page","1").set("pageSize","20"); */
    });
  }

  updateReceipt(receipt: Receipt): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/receipts/${receipt.receiptId}`, receipt, {
    });
  }

  moveReceiptToRecycleBin(receiptId: number): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/receipts/${receiptId}/recycle`, {
    });
  }
  restoreReceiptInRecycleBin(receiptId: number): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/receipts/${receiptId}/restore`, {
    });
  }
  deleteReceiptInRecycleBin(receiptId: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.baseUrl}/receipts/${receiptId}`, {
    });
  }

  getReceiptFile(receiptId: number): Observable<any> {
    const httpOptions = { responseType: 'blob' as 'json' };
    return this.httpClient.get<any>(`${this.baseUrl}/receipts/${receiptId}/pdf`, httpOptions);
  }

  getReceiptEmail(receiptId: number): Observable<any> {
    const httpOptions = { responseType: 'blob' as 'json' };
    return this.httpClient.get<any>(`${this.baseUrl}/receipts/${receiptId}/email`, httpOptions);
  }

  getReceiptFullPdfUrl(receiptId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseUrl}/receipts/${receiptId}/fullPdfUrl`, {
    });
  }

  getReceiptAttachmentUrl(receiptId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseUrl}/receipts/${receiptId}/attachmentUrl`, {
    });
  }

  /*getFileFromAWSS3(signedUrl: string): Observable<any> {
    return this.httpClient.get<any>(`${signedUrl}`, {
    });
  }*/

  getFileFromAWSS3(signedUrl: string): Observable<any> {
    const httpOptions = { responseType: 'blob' as 'json' };
    return this.httpClient.get<any>(`${signedUrl}`, httpOptions);
  }
  getReceiptsRecycled(merchantName: string, labelId: number, periodId: number, currency: string): Observable<Receipt[]> {
    return this.httpClient.get<Receipt[]>(`${this.baseUrl}/receipts/recycles`, {
      params: new HttpParams()
        .set('merchantName', merchantName)
        .set('labelId', labelId.toString())
        .set('periodId', periodId.toString())
        .set('currency', currency.toString()),
      /*params: new HttpParams({fromString: "page=1&pageSize=20"}),*/
      /*or better way: .set("page","1").set("pageSize","20"); */
    });
  }

  getReceiptsRecycledWithTotals(
    merchantName: string, labelId: number, periodId: number, currency: string):
    Observable<ReceiptWithTotals[]> {
    return this.httpClient.get<ReceiptWithTotals[]>(`${this.baseUrl}/receipts/recycles/withTotals`, {
      params: new HttpParams()
        .set('merchantName', merchantName)
        .set('labelId', labelId.toString())
        .set('periodId', periodId.toString())
        .set('currency', currency.toString()),
      /*params: new HttpParams({fromString: "page=1&pageSize=20"}),*/
      /*or better way: .set("page","1").set("pageSize","20"); */
    });
  }

  getReceiptsRecycledWithFilters(): Observable<ReceiptWithFilters[]> {
    return this.httpClient.get<ReceiptWithFilters[]>(`${this.baseUrl}/receipts/recycles/withFilters`, {
      /*params: new HttpParams({fromString: "page=1&pageSize=20"}),*/
      /*or better way: .set("page","1").set("pageSize","20"); */
    });
  }

  getMerchants(): Observable<Merchant[]> {
    return this.httpClient.get<Merchant[]>(
      `${this.baseUrl}/merchants`, {
      /*params: new HttpParams({fromString: "page=1&pageSize=20"}),*/
      /*or better way: .set("page","1").set("pageSize","20"); */
    });
  }

  getMerchantsWithCurrencies(): Observable<MerchantWithCurrency[]> {
    return this.httpClient.get<MerchantWithCurrency[]>(`${this.baseUrl}/merchants/withCurrencies`, {
      /*params: new HttpParams({fromString: "page=1&pageSize=20"}),*/
      /*or better way: .set("page","1").set("pageSize","20"); */
    });
  }

  updateMerchant(merchant: Merchant): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/merchants/${merchant.merchantId}`, merchant, {
    });
  }

  removeMerchant(merchantId: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.baseUrl}/merchants/${merchantId}`, {
    });
  }

  addMerchant(merchant: Merchant): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/merchants`, merchant, {
    });
  }

  getLabels(): Observable<Label[]> {
    return this.httpClient.get<Label[]>(`${this.baseUrl}/labels`, {
    });
  }

  getCurrencyCodes(): Observable<Currency[]> {
    return this.httpClient.get<Currency[]>(`${this.baseUrl}/currencies`, {
    });
  }

  getMyCurrencyCodes(): Observable<Currency[]> {
    return this.httpClient.get<Currency[]>(`${this.baseUrl}/myCurrencies`, {
    });
  }

  addCurrency(currency: Currency): Observable<Currency[]> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/myCurrencies`, currency, {
    });
  }

  removeCurrency(currencyId: number): Observable<Currency[]> {
    return this.httpClient.delete<any>(
      `${this.baseUrl}/myCurrencies/${currencyId}`, {
    });
  }

  updateLabel(label: Label): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/labels/${label.labelId}`, label, {
    });
  }

  removeLabel(labelId: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.baseUrl}/labels/${labelId}`, {
    });
  }

  addLabel(label: Label): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/labels`, label, {
    });
  }

  getYourOfferHereFile(): Observable<Blob> {
    const httpOptions = { responseType: 'blob' as 'json' };
    return this.httpClient.get<any>(`${this.baseUrl}/offers`, httpOptions);
  }

  getOffers(page: number, pageSize: number): Observable<Offer[]> {
    return this.httpClient.get<Offer[]>(`${this.baseUrl}/offers`, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('pageSize', pageSize.toString()),
    });
  }

  getOffersWithImageLinks(page: number, pageSize: number): Observable<Offer[]> {
    return this.httpClient.get<Offer[]>(`${this.baseUrl}/offers/withSmallImages`, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('pageSize', pageSize.toString()),
    });
  }

  countOfferLink(offerId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/offers/${offerId}/count`,
      {});
  }

  getOthers(): Observable<Other[]> {
    return this.httpClient.get<Other[]>(`${this.baseUrl}/others`,
      {});
  }

  removeOther(otherId: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.baseUrl}/others/${otherId}`, {
    });
  }

  getOtherEmail(otherId: number): Observable<any> {
    const httpOptions = { responseType: 'blob' as 'json' };
    return this.httpClient.get<any>(`${this.baseUrl}/others/${otherId}/email`, httpOptions);
  }

  getIntroduction(language: string): Observable<Intro> {
    return this.httpClient.get<Intro>(`${this.baseUrl}/intro/${language}`,
      {});
  }

  getSubscriptionForUser(currency: string): Observable<SubscriptionForUser> {
    return this.httpClient.get<SubscriptionForUser>(`${this.baseUrl}/subscriptions/forUser`, {
      params: new HttpParams()
        .set('currency', currency),
    });
  }

  createPaymentIntent(paymentIntentRequest: PaymentIntentRequest): Observable<PaymentIntent> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/subscriptions/paymentIntent`, paymentIntentRequest, {
    });
  }

  stopAutoPayment(): Observable<SubscriptionForUser> {
    return this.httpClient.put<SubscriptionForUser>(
      `${this.baseUrl}/subscriptions/autoRenewal/off`, {
    });
  }

}
