import { Subscription, SubscriptionForUser } from './../../shared/yubilly-data';
// import { environment } from './../../../environments/environment';
import { faSpinner, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { YubillyService } from './../../shared/yubilly.service';
import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PlatformLocation } from '@angular/common';
import { PaymentIntentRequest, PaymentIntent } from '@app/shared/yubilly-data';
import { ModalConfigComponent } from '../modal-config/modal-config.component';

// These two are coming from stripe.js included in index.html.
// Other ways did not work. This way will also work on clients side implements...
declare var stripe: any;
declare var elements: any;
//
@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})

export class MembershipComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLanguage = 'en';
  location: PlatformLocation;
  iconSpinner = faSpinner;
  iconOk = faCheck;
  iconX = faTimes;
  fetching = false;
  showCheckout = false;
  PERIOD_MONTHLY = 1;
  PERIOD_YEARLY = 2;
  selectedPeriod = this.PERIOD_MONTHLY;
  selectedType : string;
  //
  CONTAINER_MAIN = 2;
  CONTAINER_CONFIRM = 4;
  CONTAINER_PAYMENT = 6;
  CONTAINER_PAYMENT_RESULT = 8;
  CONTAINER_NOT_READY = 0;
  CONTAINER_HAS_SUB = 1;
  CONTAINER_NO_SUB = 3;
  activeContainer: number = this.CONTAINER_NOT_READY; //this.CONTAINER_MAIN;
  isModalSelectionActive = false;
  //
  destroy$: Subject<boolean> = new Subject<boolean>();
  //
  paymentIntent: PaymentIntent;
  paymentElement: any;
  subscriptionForUser = {} as SubscriptionForUser;
  autoRenewal = true;
  //
  submitting = false;
  constructor(
    private yubillyService: YubillyService,
    private router: Router,
    private modalConfig: ModalConfigComponent) {
  }

  ngOnInit(): void {
    this.subscriptionForUser.subFree = {} as Subscription;
    this.subscriptionForUser.subPro = {} as Subscription;
    const clientSecret =
      new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (clientSecret) {
      this.activeContainer = this.CONTAINER_PAYMENT_RESULT;
      // this.router.navigate(['/'], { replaceUrl: true });
      this.checkStatus(clientSecret);
    } else {
      this.getMembershipForUser(null); // currency will come from yservice.
      this.activeContainer = this.CONTAINER_NOT_READY; //this.CONTAINER_MAIN;
    }
  }
  ngAfterViewInit(): void {
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    try {
      this.paymentElement.unmount();
    } catch (error) { }
    //window.location.replace('');
  }

  getMembershipForUser(currency: string): void {
    this.fetching = true;
    if (currency == null) {
      currency = '';
    }
    this.yubillyService
      .getSubscriptionForUser(currency)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          //console.log('getMembershipForUser returns:');
          //console.log(res);
          this.subscriptionForUser = res;
          this.fetching = false;
          //console.log(this.subscription);
          this.activeContainer = this.CONTAINER_MAIN;
          /*if (this.subscription.hasPayment || !this.subscription.hasSubscription) {
            this.activeContainer = this.CONTAINER_NO_SUB;
            this.showCheckout = true;
          } else {
            this.activeContainer = this.CONTAINER_HAS_SUB;
            this.showCheckout = false;
          }*/
        },
        (error) => {
          this.fetching = false;
        }
      );
  }
  autoRenewalChecked(): void {
    this.autoRenewal = (document.getElementById('recurring') as HTMLInputElement).checked;
    // console.log('this.autoPayment='+this.autoRenewal);
  }
  upgrade(): void{
    this.activeContainer = this.CONTAINER_CONFIRM;
  }
  renew(subType: string): void{
    this.selectedType = subType;
    this.activeContainer = this.CONTAINER_CONFIRM;

  }
  cancelCheckout(): void{
    this.activeContainer = this.CONTAINER_MAIN;
  }
  stopAutoRenewal(content: string): void{
       // Are you sure?
       history.pushState(null, null);
       this.isModalSelectionActive = true;
       this.modalConfig.openV2(content).then((result: any) => {
         this.isModalSelectionActive = false;
         this.fetching = true;
         this.yubillyService
         .stopAutoPayment()
         .pipe(takeUntil(this.destroy$))
         .subscribe(
           (res) => {
             console.log('stopAutoRenewal returns:');
             console.log(res);
             this.subscriptionForUser = res;
             this.fetching = false;
             //this.activeContainer = this.CONTAINER_MAIN;
           },
           (error) => {
             this.fetching = false;
           }
         );

       }).catch((reason: any) => {
         if (reason !== 'back') {
           this.isModalSelectionActive = false;
         }
       });
  }

  onPeriodChange(x: number) {
    if (x == 1) {
      this.selectedPeriod = this.PERIOD_MONTHLY;
    } else {
      this.selectedPeriod = this.PERIOD_YEARLY;
    }
  }

  onCheckout(sub:Subscription): void {
    this.activeContainer = this.CONTAINER_PAYMENT;
    // get payment intent from yservice
    this.fetching = true;
    const item: PaymentIntentRequest = {
      subId: sub.subId,
      period: (this.selectedPeriod === this.PERIOD_MONTHLY
        ? sub.periodForMonthly : sub.periodForYearly),
      pointToUse:(this.selectedPeriod === this.PERIOD_MONTHLY ?
        sub.pointToUseInMonthly : sub.pointToUseInYearly),
      creditToUse:(this.selectedPeriod === this.PERIOD_MONTHLY ?
        sub.creditToUseInMonthly : sub.creditToUseInYearly),
      amountToPay: (this.selectedPeriod === this.PERIOD_MONTHLY ?
        sub.amountToPayMonthly : sub.amountToPayYearly),
      currency: '',
      autoRenewal: this.autoRenewal
    };
    console.log('item to send for creating PI:');
    console.log(item);
    // console.log("item:"+item.subId+","+item.availableCredit+","+item.amountToPay);
    this.yubillyService
      .createPaymentIntent(item)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          //console.log('createPaymentIntent returns:');
          //console.log(res);
          this.paymentIntent = res;
          this.fetching = false;
          //
          (document.getElementById('receiptEmailValue') as HTMLSpanElement).textContent = this.paymentIntent.receiptEmail;
          (document.getElementById('descriptionValue') as HTMLSpanElement).textContent = this.paymentIntent.description;
          (document.getElementById('amountValue') as HTMLSpanElement).textContent =
            (this.paymentIntent.amount + ' ' + this.paymentIntent.currency.toUpperCase());
          //
          const clientSecret = this.paymentIntent.clientSecret;
          const appearance = {
            theme: 'stripe',
            variables: {
              // colorPrimary: '#0570de',
              // colorBackground: '#ffffff',
              // colorText: '#30313d',
              // colorDanger: '#df1b41',
              fontFamily: 'Ideal Sans, system-ui, sans-serif',
              spacingUnit: '2px',
              borderRadius: '2px',
            }
          };
          elements = stripe.elements({ appearance, clientSecret });
          // const linkAuthenticationElement = elements.create("linkAuthentication");
          // linkAuthenticationElement.mount("#link-authentication-element");
          /*linkAuthenticationElement.on('change', (event) => {
            this.emailAddress = event.value.email;
          });*/
          // !!! add the amount to button (this.paymentIntent.amount + this.paymentIntent.currency);
          const paymentElementOptions = {
            layout: "tabs",
            fields: {
              billingDetails: {
                // name: 'never',
                email: 'never',
              }
            }
          };
          this.paymentElement = elements.create("payment", paymentElementOptions);
          this.paymentElement.mount("#payment-element");
        },
        (error) => {
          console.log(error);
          this.fetching = false;
        }
      );
  }

  async handleSubmit() {
    // e.preventDefault();
    (document.getElementById('submit') as HTMLButtonElement).disabled = true;
    (document.getElementById('cancel-payment') as HTMLButtonElement).disabled = true;
    document.querySelector('#spinner').classList.remove('hidden');
    document.querySelector('#button-text').classList.add('hidden');
    //
    const r = (window.location.origin).concat('/subscription');
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: r,
        payment_method_data: {
          billing_details: {
            // name: 'Mithat K.',
            email: this.paymentIntent.receiptEmail,
          }
        },
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`with the following parameters
    // ?payment_intent=pi_3MorZ6JGQGKuwjSM1COhsHYB
    // &payment_intent_client_secret=pi_3MorZ6JGQGKuwjSM1COhsHYB_secret_sl7ADNWOM8zcntMmrqg1s4Vxt
    // &redirect_status=succeeded
    if (error.type === 'card_error' || error.type === 'validation_error') {
      console.log(error.message);
    } else {
      console.log('An unexpected error occurred.');
    }
    (document.getElementById('submit') as HTMLButtonElement).disabled = false;
    (document.getElementById('cancel-payment') as HTMLButtonElement).disabled = false;
    document.querySelector('#spinner').classList.add('hidden');
    document.querySelector('#button-text').classList.remove('hidden');
  }

  // Fetches the payment intent status after payment submission
  async checkStatus(clientSecret: any) {
    /*const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    // const clientSecret = this.paymentIntent.clientSecret;*/
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = stripe.retrievePaymentIntent(clientSecret);
    switch (paymentIntent.status) {
      case "succeeded":
        this.activeContainer = this.CONTAINER_PAYMENT_RESULT;
        console.log("Payment succeeded!");
        // TODO
        // display success message for user and go to home page.
        // 'paymentSucceeded'|translate
        try {
          this.paymentElement.unmount();
        } catch (error) { }
        break;
      case "processing":
        console.log("Your payment is processing.");
        break;
      case 'requires_payment_method':
        // display the message to user, then stay on payment page
        console.log("Your payment was not successful, please try again.");
        break;
      default:
        // display the message to user, then stay on payment page
        console.log("Something went wrong.");
        break;
    }
  }

  cancelPayment(): void {
    this.activeContainer = this.CONTAINER_HAS_SUB;
  }

  paymentDone(): void {
    // window.location.replace('');
    // browser.history.deleteUrl();
    // window.location.origin;
    // const url = window.location.href;
    // const urlObj = new URL(window.location.href);
    // urlObj.search = '';
    // const result = urlObj.toString();
    // window.location.replace(urlObj.toString());
    // window.location.replace('');
    // this.router.navigateByUrl('/');
    this.router.navigate(['/'], { replaceUrl: true });
  }
}
